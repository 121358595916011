import React from "react"
import { CameraIcon } from "@heroicons/react/solid"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const pageQuery = graphql`
  query FeatureQuery {
    file(relativePath: { eq: "about-me.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
const thingsIAdore = [
  <>smoothie bowls + green juice - heavy on the greens </>,
  <>intuitive movement - working with my natural energy shifts </>,
  <>lattes - matcha / espresso / turmeric / cacao / whatever! </>,
  <>early mornings - waking up with the sun is never a regret </>,
  <>nature walks + a good podcast</>,
  <>farmers markets + nutritious, whole foods</>,
  <>all things travel - by road (#vanlife), sea, or plane </>,
  <>my sister friends + family near and far</>,
  <>my heavily manifested partner + best friend </>,
  <>books over tv - every time</>
]

const photographer = ""

export default function Example() {
  const data = useStaticQuery(pageQuery)
  const image = getImage(data?.file)

  return (
    <div className="bg-white overflow-hidden bg-blob-scatter-small bg-cover bg-no-repeat bg-center">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h1 className="text-base text-sea-pink-700 font-semibold tracking-wide uppercase">
              Owner
            </h1>
            <h2 className="mt-2 text-4xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              Hey Lovely, <span className="text-sea-pink-700">I’m Eden!</span>
            </h2>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <div className="relative text-base rounded-lg overflow-hidden mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div>
                  <GatsbyImage
                    className="rounded-lg shadow-lg object-cover object-center "
                    image={image}
                    alt="Eden standing in front of be kind artwork"
                    width={1184}
                    height={1376}
                  />
                </div>
                {photographer && (
                  <figcaption className="mt-3 flex text-sm text-jet">
                    <CameraIcon
                      className="flex-none w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2">Photograph by Jon Doe</span>
                  </figcaption>
                )}
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="text-lg text-jet">
                With a Bachelor of Science in Nursing, over 500 hours of Yoga
                Teacher Training, and a Certification in Integrative Nutrition,
                my mission is to use my diverse knowledge to encourage women to
                harness their ability to live a comfortable, confident, and
                symptom-free life each and every day of their cycle.
              </p>
            </div>
            <div className="mt-5 prose prose-sea-pink text-jet mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p>
                On top of personally experiencing dead-end treatments for my
                acne, fatigue, lost periods, anxiety, and immune dysfunction, I
                continue to witness many patients undergo drastic, preventable
                procedures in my nursing practice. And it’s about time we shift
                this process.
              </p>
              <p>
                I am here to help you create meaningful and maintainable
                lifestyle changes by:
              </p>
              <ol role="list">
                <li>
                  Treating the root cause, rather than providing a band-aid
                  approach
                </li>
                <li>
                  Focusing on ALL Determinants of Health, or what I like to call
                  Primary Foods. These are the, often overlooked, areas of
                  health OFF the plate
                </li>
                <li>
                  Creating a safe space for you to do the ‘inner work’, allowing
                  a true glow from the inside out
                </li>
                <li>
                  Offering you tangible resources that fit your specific needs
                </li>
                <li>
                  Incorporating consistency, repetition & accountability into
                  your program to create lasting, holistic habits
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 bg-blobs text-center text-jet flex mx-auto pt-12 justify-center py-12 border-porsche-300 border-t-2 border-b-2">
        <div>
          <q className="text-4xl leading-8 font-extrabold tracking-tight">
            Things I Unapologetically Adore
          </q>
          <div className="mt-8">
            <ul>
              {thingsIAdore.map(item => (
                <li className="mt-2" key={item}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
