import FeatureWithImage from "../components/featureWithImage"
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `about page`,
          `sincerely eden`,
          `challenge`,
          `about sincerely eden`
        ]}
        title="Sincerely Eden | Learn about"
        description="Checkout why Sincerely Eden is pationate, and more than qualified to look after you."
      />
      <FeatureWithImage />
    </Layout>
  )
}

export default ContactPage
